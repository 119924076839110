<template>
    <div class="springAwakening_redEnvelope iphonex-pb">
        <ScrollHead left-arrow @click-left="newAppBack" title="开工领红包" :placeholder="false"> </ScrollHead>
        <div class="headbg">
            <div class="htop">
                <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/head.png" />
            </div>
            <div class="swiper">
                <van-swipe style="height: 100%;" vertical :autoplay="3000" :show-indicators="false">
                    <van-swipe-item v-for="item in scrollData">恭喜尾号{{ item.winner }}用户抽中{{ item.prizeName
                        }}!</van-swipe-item>
                </van-swipe>
            </div>
            <div class="light">
                <div class="red">
                    <div class="username uc puhui">
                        【<span class="uv ellipsis">{{ userDetail.nickName }}</span>】
                    </div>
                    <div class="uc puhui">专属红包</div>
                    <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/open.png" class="open ani"
                        @click="openRed" />
                    <div class="sy" @click="openFrom">
                        开奖机会剩 <span class="value BebasNeueBold">{{ numOfLottery }}</span> 次<van-icon name="arrow"
                            class="jt" />
                    </div>
                </div>

            </div>

            <div class="rs">
                <div class="sec1" @click="toShare">分享</div>
                <div class="sec2" @click="toRule">规则</div>
            </div>
        </div>
        <div class="gn">
            <div class="gnm g1" @click="openMission"></div>
            <div class="gnm g2" @click="openMyPrize"></div>
        </div>
        <div class="bg1">
            <div class="gbox">
                <div class="pd">
                    <div class="sc">
                        <div class="trans-box">
                            <div class="gmodel" v-for="item in prizeList">
                                <div class="g1">
                                    <img :src="item.picUrl" />
                                </div>
                                <div class="g2">
                                    <div class="pname ellipsis-2">{{ item.name }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg3" v-if="missionList.length">
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/mission1.png" />
            <div class="mission-list">
                <div class="mission-model" v-for="(item, index) in missionList">
                    <div class="mission-logo">
                        <img :src="item.src" />
                    </div>
                    <div class="mission-info">
                        <div class="mission-name">{{ item.name }}</div>
                        <div class="mission-num">
                            {{ item.label }}<span v-if="item.totalNum !== undefined">（{{ item.completionNum
                                }}/{{
                                    item.totalNum
                                }}）</span>
                        </div>
                    </div>
                    <div v-if="status == 'END'" class="mission-sub dis">已结束</div>
                    <div v-else class="mission-sub" :class="{ dis: item.completionStatus == 1 }"
                        @click="countPoint('600', '600', '60016'), item.fun()">
                        {{ item.completionStatus == 1 ? '已完成' : item.buttonText }}</div>
                </div>
            </div>
            <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/mission2.png" class="m2" />
        </div>

        <transition name="bounce">
            <div class="popget" v-if="showred">
                <div class="getbg">
                    <div class="getimg">
                        <img :src="rewardJson.picUrl" />
                    </div>
                    <div class="getname puhui ellipsis">{{ rewardJson.prizeName }}</div>
                    <div class="getlabel">
                        <template v-if="rewardJson.needMail">
                            请尽快填写您的收货地址，我们将<br />
                            通过快递邮寄给您。
                        </template>
                        <template v-else>
                            系统已发放至您的账户，使用规则<br />可在我的奖品页内查看
                        </template>
                    </div>
                    <div class="subline">
                        <div class="sm s1 puhui" @click="showred = false">我知道了</div>
                        <div class="sm s2 puhui" @click="again">再开一次</div>
                    </div>
                </div>
                <div class="close_red">
                    <van-icon name="cross" color="#fff" @click="showred = false" />
                </div>
            </div>
        </transition>
        <van-overlay :show="showred" z-index="800" />

        <van-popup v-model="showFirst" position="center" class="popfirst" :close-on-click-overlay="false">
            <div class="frbg">
                <div class="fusername uc puhui">【<span class="uv ellipsis">{{ userDetail.nickName }}</span>】</div>
                <div class="uc puhui">专属红包</div>
                <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/open.png" class="fopen ani"
                    @click="openFirst" />
            </div>
        </van-popup>
        <van-popup v-model="showNoNum" position="center" class="popnonum" :close-on-click-overlay="false">
            <div class="line1">您没有抽奖机会啦!</div>
            <div class="line2">完成任务即可获得更多抽奖机会</div>
            <div class="num-subline">
                <div class="num-subleft numbase" @click="showNoNum = false">再考虑下</div>
                <div class="num-subright numbase" @click="showNoNum = false, openMission()">去做任务</div>
            </div>
        </van-popup>
        <van-popup v-model="showRule" position="bottom" safe-area-inset-bottom class="poprule">
            <div class="pop-title">
                <span>活动规则</span>
                <van-icon name="cross" class="pop-close" color="#fff" @click="showRule = false" />
            </div>
            <div class="rule-content">
                <div>1.活动时间：2025年2月5日10:00-3月9日</div>
                <div>2.活动奖品兑换规则：</div>
                <div>① 课程优惠券<br/>
                    活动期间获得的课程优惠券将自动发放至用户账户，可在在APP"我的-优惠券"查看，优惠券均有设置有效期，请在有效期内尽快使用，逾期失效。</div>
                <div>② 春醒活动抵用券<br/>
                    仅限春醒活动产品优惠使用，具体使用产品规则以优惠券页面详情为准，每个订单仅可用一张优惠券。春醒活动结束，优惠券失效。</div>
                <div>③ 实物奖品<br/>
                    如用户抽中实物奖品“u型枕”和“手机暴富贴”均以快递的形式发放，请中奖用户自行填写邮寄地址，快递签收即确认收货。实物奖品均以实物为准。实物发货时间：2月28日前中奖用户发货时间为3月1日；2月28日后中奖的用户，发货时间为3月10日；蛇形颈枕均为随机颜色发货，不接受指定颜色。
                </div>
                <div>3.以上所有奖券均不可提现，逾期失效。</div>
                <div>4.对于本次活动的内容，主办方保留在不违反法律法规的前提下进行解释的权利。如有疑问可联系客服：400-700-5678。</div>
            </div>
        </van-popup>
        <MyDrawFrom ref="refmyDrawFrom" :numOfLottery="numOfLottery" />
        <MyPrize ref="refMyPrize" />
        <CommonShare :value="showShare" :shareParams="shareParams" @close="showShare = false"
            @share-success="showShare = false" @click-share-item="shareClick"
            :shareItem="['minifriend', 'postermessage', 'save']" :miniSharePoster="appTypeStr === 'mini'">
            <template slot="createDom">
                <div class="canvascss">
                    <img src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/sharebg.png"
                        class="canvascss_bg" />
                    <div class="canvascss_code">
                        <img v-if="userDetail.qrCode" :src="`data:image/png;base64,${userDetail.qrCode}`" />
                        <div class="canvascss_code_text">扫码领取福利</div>
                    </div>
                    <div class="canvascss_info">
                        <img :src="userDetail.headImg" class="canvascss_head" />
                        <div class="canvascss_full">
                            <div class="canvascss_name ellipsis-2">
                                {{ userDetail.nickName }}
                                <!-- 啊实打实大师大萨达四大四大三 -->
                            </div>
                        </div>
                    </div>

                </div>
            </template>
        </CommonShare>
    </div>
</template>
<script>
import { newAppBack, initBack, gotoAppPage } from '@/lib/appMethod';
import ScrollHead from '@/components/app/scrollHead';
import CommonShare from '@/components/commonShare/newShare';
import MyDrawFrom from './components/myDrawFrom.vue';
import MyPrize from './components/myPrize.vue';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk';

const webPath = `${window.location.origin}/#/superdeer-activity/spring-awakening-2025/red-envelope`
const miniPath = `/pages/webView/index?webUrl=${encodeURIComponent(webPath)}`

export default {
    components: {
        CommonShare,
        MyDrawFrom,
        MyPrize,
        ScrollHead,
    },
    mixins: [userMixin],
    data() {
        return {
            missionList: [],
            numOfLottery: 0,
            showMission: false,
            showNoNum: false,
            showred: false,
            inOpen: false,
            showShare: false,
            status: undefined,
            shareItem: ['minifriend', 'postermessage', 'save'],
            shareParams: {
                title: '开春领红包，最高得1000元抵用券~',
                miniImage:
                    'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/mini.png',
                path: '',
                miniProgramType:
                    window.location.origin.indexOf('web.chaolu.com') > -1
                        ? '0'
                        : '2', // 正式0，开发1，体验2
                url: 'url',
                multiple: '0.95',
                userName: 'gh_7f5b57b6a76e',
            },
            userDetail: {}, //分享的用户信息
            activity: {
                count: 0, //共可以抽奖次数
                receiveCount: 0, //分享被点击次数
            },
            rewardJson: {}, //开出的奖品
            prizeList: [{}, {}, {}, {}, {}, {}, {}, {},],
            showRule: false,
            showFirst: false,
            scrollData: []
        };
    },
    methods: {
        newAppBack,
        again() {
            this.showred = false;
            this.$nextTick(() => {
                this.openRed();
            })
        },
        async openFirst() {
            this.openRed();
            this.showFirst = false;
        },
        cj() {
            return this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/luckDraw", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data || [];
                this.rewardJson = d;
                this.getData();
            })
        },
        async openRed() {
            this.countPoint('600', '600', '60014')
            if (this.status != 'ACTIVE') {
                this.$toast('活动未开启');
                return;
            }
          
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            try {
                await this.getNum();
                if (!this.numOfLottery) {
                    this.showNoNum = true;
         
                    this.$toast.clear()
                    return;
                }

                // window.scrollTo(0, 0);
                await this.cj();
                this.$toast.clear()
           
                this.showred = true;
           
            } catch (error) {
            
                this.$toast.clear()
            }
        },
        bindRefresh() {
            const getNum = async () => {
                if (document.visibilityState === "visible") {
                    this.getData();
                    document.removeEventListener("visibilitychange", getNum);
                } else if (document.visibilityState === "hidden") {
                }
            }
            document.addEventListener("visibilitychange", getNum);
        },
        getMissionInfo() {
            const missionIntoJson = {
                1: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m1.png',
                    name: '每日进入活动页',
                    label: '每日最多可得1次',
                    buttonText: '已完成',
                    fun: () => { }
                },
                7: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m7.png',
                    name: '每日在馆30分钟',
                    label: '累计满30分钟可得1次',
                    buttonText: '去完成',
                    fun: () => {
                        this.$toast('暂未完成任务，快去进馆运动吧')
                    }
                },
                3: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m3.png',
                    name: '邀请有礼活动-邀请1人',
                    label: '每邀请1人得2次·无上限',
                    buttonText: '去完成',
                    fun: () => {
                        this.$router.push('/invite-gift');
                        window.scrollTo(0, 0);
                    }
                },
                4: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m4.png',
                    name: '每日分享活动',
                    label: '每日最多可得5次',
                    buttonText: '去分享',
                    fun: () => {
                        this.toShare();
                    }
                },
                5: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m5.png',
                    name: '打卡团课',
                    label: '每打卡1节得1次·无上限',
                    buttonText: '去完成',
                    fun: () => {
                        gotoAppPage(3, '');
                    }
                },
                6: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m6.png',
                    name: '打卡小班课',
                    label: '每打卡1节得1次·无上限',
                    buttonText: '去完成',
                    fun: () => {
                        gotoAppPage(8, '');
                    }
                },
                2: {
                    src: 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/m2.png',
                    name: '打卡私教课',
                    label: '每打卡1节得2次·无上限',
                    buttonText: '去完成',
                    fun: () => {
                        gotoAppPage(1, '');
                    }
                }
            }
            return this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getTaskMainPageInfo", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data.taskCompletionStatus || [];
                for (let i = 0; i < d.length; i++) {
                    d[i] = { ...d[i], ...missionIntoJson[d[i].type] };
                }
                this.missionList = d;
            })
        },
        openFrom() {
            this.countPoint('600', '600', '60017')
            this.$refs.refmyDrawFrom.show();
        },
        openMyPrize() {
            this.countPoint('600', '600', '60018')
            this.$refs.refMyPrize.show();
        },
        openMission() {
            this.countPoint('600', '600', '60015')
            window.scrollTo({
                top: 10000,
                behavior: "smooth"
            });
        },
        toRule() {
            this.showRule = true;
        },
        toShare() {
            if (!this.userDetail.qrCode) {
                this.$toast('图片加载中,情稍后再试')
                return;
            }
            this.countPoint('600', '600', '60011')
            this.showShare = true;
            this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/shareLink", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                this.getData();
            })
        },
        shareClick(v) {
            if (v == 'minifriend') {
                this.countPoint('600', '600', '60012')
            } else if (v == 'postermessage') {
                this.countPoint('600', '600', '60013')
            }
        },
        shareSuccess() {
            this.showShare = false;
        },
        getData() {
            this.getMissionInfo();
            this.getNum();
        },
        getNum() {
            return this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getParticipateData", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data;
                this.numOfLottery = d.count;
                this.status = d.activityActiveTimeCheck;
                if (this.status !== 'ACTIVE') {
                    this.close();
                }
            })
        },
        firstLogin() {
            return this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/loginPage", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data;
                if (d) {
                    this.showFirst = true;
                }
            })
        },
        getRewardList() {
            return this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/getPrizes", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data;
                this.prizeList = d || [];
            })
        },
        initQr() {
            this.$axios
                .post(`${this.baseURLApp}/qrCode/createOrdinaryCode`, {
                    token: this.token,
                    userId: this.userId,
                    width: 240,
                    height: 240,
                    webUrl: 'https://web.chaolu.com.cn/#/ordinary_004001',
                    scene: JSON.stringify({
                        source: 'springpre2025',
                        webUrl: webPath,
                        SUID: this.userId,
                        userId: 1,
                        token: 1,
                    }),
                })
                .then((res) => {
                    this.userDetail = res.data;
                });
        },
        close() {
            this.$toast.clear()
            this.$dialog
                .alert({
                    message: ' <b>很抱歉，该活动已经结束！<br/>去看看超鹿其他的活动吧</b>',
                    confirmButtonText: '我知道了',
                    className: 'dialog',
                    overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' },
                })
                .then(() => {
                    if (this.appTypeStr == 'mini') {
                        wx.miniProgram.switchTab({ url: '/pages/home/index' })
                    } else {
                        this.newAppBack()
                    }
                })
        },
        getScroll() {
            this.$axios.post(this.baseURLApp + "/prizeDrawActivity/2025/backToWorkRedEnvelope/rotateWinningInfo", {
                hasWx: true,
                userId: this.userId,
            }).then((res) => {
                console.log(res.data);
                const d = res.data || [];
                this.scrollData = d;
            })
        }
    },
    beforeDestroy() {
        // clearTimeout(this.timer);
        // this.$refs.hscroll.removeEventListener('transitionend',this.startScroll)
    },
    async created() {
        initBack();
        await this.$getUserId();
        const q = this.$route.query;
        const fromId = q.fromId;
        if (fromId) {
            this.countPoint('600', '600', fromId, this.userId, this.appTypeStr == "mini" ? 'MINI' : 'APP')
        }
        if (this.appTypeStr === "mini") {
            this.countPoint('600', '600', '60008')
        } else {
            this.countPoint('600', '600', '60009')
        }
        if (q.teacherId || q.inviteTeacherId) {
            this.countPoint('600', '600', '60010')
        }
        if (!this.userId) {
            this.$store.commit('openLoginPopup', true)
            return
        }
        this.shareParams.path = this.shareParams.path = `${miniPath}&SUID=${this.userId}`;
        if (this.appTypeStr == 'mini') {
            wx.miniProgram.postMessage({
                data: {
                    type: 'share',
                    shareImage: this.shareParams.miniImage,
                    shareTitle: this.shareParams.title,
                    shareUrl: this.shareParams.path,
                },
            });
        }
        if (this.userId && this.token) {
            // this.shareUserId = this.$route.query.SUID; //分享人id
            this.firstLogin().then(() => {
                this.getMissionInfo();
            });
            this.getNum();
            this.getRewardList();
            this.getScroll();
            this.initQr();
            this.bindRefresh();
        }
    },
    mounted() {
        var img = new Image();
        img.src = 'https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/getbg.png'
    }
};
</script>
<style>
@keyframes animationBounce {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@-webkit-keyframes animationBounce {
    0% {
        -webkit-transform: scale(1);
    }

    50% {
        -webkit-transform: scale(1.2);
    }

    100% {
        -webkit-transform: scale(1);
    }
}

@keyframes animationOpen {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(0.5);
    }
}

@-webkit-keyframes animationOpen {
    0% {
        -webkit-transform: scale(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scale(0.5);
        opacity: 0;
    }
}

.bounce-enter-active {
    animation: bounce-in .5s;
}

.bounce-leave-active {
    animation: bounce-in .5s reverse;
}

@keyframes bounce-in {
    0% {
        transform: scale(0);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}
</style>
<style lang="less">
.springAwakening_redEnvelope {
    background: #FD8D61;
    min-height: 100vh;
    padding-bottom: 100px;

    div {
        box-sizing: border-box;
    }

    img {
        width: 100%;
        vertical-align: top;
    }


    .headbg {
        height: 1285px;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/headbg.png);
        background-size: 100% 100%;
        // background: linear-gradient(0deg, #FD8D61 0%, #FF9A6E 45%, #E82822 100%);
        position: relative;

        .htop {
            position: relative;
            z-index: 7;
            height: 465px;

            img {
                height: 100%;
            }
        }

        .swiper {
            width: 470px;
            overflow: hidden;
            height: 44px;
            line-height: 46px;
            background: rgba(0, 0, 0, 0.18);
            border-radius: 22px;
            margin: 12px auto 0;
            position: relative;
            z-index: 10;
            padding: 0 32px;
            font-size: 22px;
            color: #FFF4E8;
            text-align: center;

            .van-swipe-item {
                overflow: hidden;
            }
        }

        .light {
            position: relative;
            top: -198px;
            height: 950px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/light.png);
            background-size: 100% 100%;
            z-index: 9;
            padding-top: 180px;

            .red {
                width: 492px;
                height: 639px;
                position: relative;
                margin: 0 auto;
                background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/red.png);
                background-size: 100% 100%;
                padding-top: 97px;

                .uc {
                    font-size: 28px;
                    color: #FFF4E8;
                    text-align: center;
                }

                .username {
                    line-height: 38px;
                    width: fit-content;
                    margin: 0 auto;
                    display: flex;

                    .uv {
                        max-width: 250px;
                    }
                }

                .open {
                    width: 162px;
                    height: 171px;
                    position: absolute;
                    top: 222px;
                    left: 50%;
                    margin-left: -81px;
                }

                .sy {
                    width: 202px;
                    height: 50px;
                    // padding-top: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: rgba(0, 0, 0, 0.15);
                    border-radius: 25px;
                    text-align: center;
                    position: absolute;
                    bottom: 88px;
                    left: 50%;
                    transform: translateX(-50%);
                    font-size: 20px;
                    color: #fff;

                    .value {
                        font-size: 28px;
                        position: relative;
                        top: 2px;
                        margin: 0 6px;
                    }

                    .jt {
                        margin-left: 8px;
                    }
                }
            }
        }

        .rs {
            position: absolute;
            right: 0;
            top: 230px;
            width: 88px;
            z-index: 99;

            .sec1,
            .sec2 {
                line-height: 56px;
                background: rgba(0, 0, 0, 0.3);
                border-top-left-radius: 28px;
                border-bottom-left-radius: 28px;
                color: #fff;
                width: 100%;
                text-align: center;
                font-size: 24px;
                margin-bottom: 32px;
                padding-left: 6px;
            }
        }


        .pd {
            padding: 0 32px;
            margin-top: 550px;
        }

    }

    .gn {
        margin: 9px 0 50px;
        padding: 0 34px;
        display: flex;
        justify-content: space-between;

        .gnm {
            width: 332px;
            height: 106px;
            background-size: 100% 100%;
        }

        .g1 {
            background-image: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/sub1.png);
        }

        .g2 {
            background-image: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/sub2.png);
        }
    }

    .bg1 {
        width: 686px;
        height: 405px;
        margin: 0 auto;
        background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/bg1.png);
        background-size: 100% 100%;
        padding-top: 87px;

        .gbox {
            width: 646px;
            height: 292px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/gbox.png);
            background-size: 100% 100%;
            margin: 0 auto;
            padding: 49px 6px 0;
            display: flex;
            position: relative;

            .pd {
                overflow: hidden;
                border-radius: 0 0 56px 56px;
            }

            .sc {
                white-space: nowrap;
                overflow-x: scroll;
                position: relative;

                .trans-box {}
            }

            .gmodel {
                margin-right: 6px;
                width: 142px;
                display: inline-table;

                &:first-of-type {
                    margin-left: 16px;
                }

                &:last-of-type {
                    margin-right: 16px;
                }

                .g1 {
                    height: 161px;
                    background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/gbg1.png);
                    background-size: 100% 100%;
                    padding-top: 30px;
                    text-align: center;

                    img {
                        width: 120px;
                        height: 120px;
                    }
                }

                .g2 {
                    height: 66px;
                    background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/gbg2.png);
                    background-size: 100% 100%;
                    position: relative;
                    margin-top: -10px;
                    padding: 0 18px;
                    display: flex;
                    align-items: center;

                    .pname {
                        font-size: 18px;
                        color: #A54900;
                        line-height: 22px;
                        font-weight: bold;
                        white-space: normal;
                        text-align: center;
                        width: 100%;
                        padding: 0 4px;
                    }
                }
            }
        }
    }

    .bg3 {
        margin: 40px auto;
        width: 686px;

        .mission-list {
            background: #FFD8B8;
            padding: 7px 20px;
            position: relative;
            z-index: 10;
            top: -1px;

            .mission-model {
                height: 124px;
                background: #fff;
                border-radius: 20px;
                margin-bottom: 16px;
                display: flex;
                align-items: center;
                padding: 0 24px 0 28px;

                &:last-of-type {
                    margin-bottom: 0px;
                }

                .mission-logo {
                    width: 76px;
                    height: 76px;
                    border-radius: 50%;
                    overflow: hidden;
                    background: #f5f5f5;

                    img {
                        height: 100%;
                    }
                }

                .mission-info {
                    flex: 1;
                    margin-left: 23px;
                    color: #242831;

                    .mission-name {
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 1;
                    }

                    .mission-num {
                        margin-top: 15px;
                        font-size: 22px;
                        line-height: 1;
                    }
                }

                .mission-sub {
                    width: 120px;
                    height: 52px;
                    background: linear-gradient(90deg, #FF6C4F 0%, #FF4C7F 100%);
                    border-radius: 10px;
                    font-size: 22px;
                    color: #FFFFFF;
                    font-weight: bold;
                    text-align: center;
                    line-height: 52px;

                    &.dis {
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
            }
        }

        .m2 {
            position: relative;
            top: -20px;
        }
    }

    .ani {
        animation: 1s animationBounce infinite linear;
        -webkit-animation: 1s animationBounce infinite linear;
    }

    .openani {
        animation: 1s animationOpen 1 ease-out forwards;
        -webkit-animation: 1s animationOpen 1 ease-out forwards;
    }

    .pop-title {
        color: #1E242B;
        font-weight: bold;
        background: linear-gradient(0deg, #fff 0%, #FFCDCD 100%);
        font-size: 36px;
        text-align: center;
        height: 116px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        .ts {
            position: relative;
            transform-style: preserve-3d;
            display: inline-block;

            &::after {
                content: '';
                position: absolute;
                left: -8px;
                bottom: 0px;
                width: 160px;
                height: 18px;
                background: linear-gradient(90deg, #FF7F7F 0%, rgba(255, 255, 255, 0) 100%);
                border-radius: 38px;
                transform: translateZ(-10px);
                z-index: -1;
            }
        }

        .pop-close {
            position: absolute;
            right: 32px;
            top: 38px;
            width: 44px;
            height: 44px;
            line-height: 44px;
            border-radius: 50%;
            background: rgba(39, 40, 49, 0.2);
            font-size: 28px;
            text-align: center;
        }
    }


    .popred {
        padding: 48px 0 0;
        border-radius: 32px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-sizing: border-box;

        .poplist {
            max-height: 890px;
            padding: 0 32px;
            overflow: auto;
        }

        .basetitle {
            margin-left: 32px;
        }

        /deep/.van-popup__close-icon {
            z-index: 100;
            color: #9aa1a9;
        }
    }

    .popget {
        width: 100%;
        background: transparent;
        overflow: visible;
        top: 50%;
        position: fixed;
        left: 0;
        right: 0;
        margin-top: -443px;
        z-index: 999;

        .getbg {
            height: 887px;
            position: relative;
            padding-top: 160px;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/getbg.png);
            background-size: 100% 100%;
            text-align: center;

            .getimg {
                width: 280px;
                height: 280px;
                margin: 0 auto;
            }

            .getname {
                font-size: 48px;
                color: #FFFFFF;
                width: 500px;

                margin: 30px auto 21px;
            }

            .getlabel {
                font-size: 24px;
                color: #FFFFFF;
                line-height: 42px;
                width: 380px;
                margin: 0 auto;
            }

            .subline {
                position: absolute;
                bottom: 125px;
                left: 50%;
                width: 480px;
                transform: translateX(-50%);
                display: flex;
                justify-content: space-between;

                .sm {
                    width: 230px;
                    height: 90px;
                    line-height: 90px;
                    border-radius: 45px;
                    font-size: 32px;
                    text-align: center;
                }

                .s1 {
                    background: linear-gradient(90deg, #FF5331 0%, #F33637 100%);
                    color: #FFFFFF;
                }

                .s2 {
                    background: linear-gradient(90deg, #D8FC9A 0%, #F5F588 100%);
                    color: #2F2023;
                }
            }
        }

        .close_red {
            width: 60px;
            height: 60px;
            border: 1px solid #fff;
            border-radius: 50%;
            left: 50%;
            position: absolute;
            top: 100%;
            transform: translateX(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 5;

            i {
                font-size: 40px;
            }
        }
    }

    .popfirst {
        width: 100%;
        background: transparent;

        .frbg {
            height: 754px;
            text-align: center;
            background: url(https://chaolu.oss-cn-hangzhou.aliyuncs.com/ACT/spring-2025/first.png);
            background-size: 100% 100%;
            position: relative;

            .fusername {
                font-size: 32px;
                color: #FFF4E8;
                line-height: 46px;
                padding-top: 106px;
                width: fit-content;
                margin: 0 auto;
                display: flex;

                .uv {
                    max-width: 300px;
                }
            }

            .uc {
                font-size: 32px;
                color: #FFF4E8;
                text-align: center;
            }

            .fopen {
                position: absolute;
                top: 258px;
                width: 198px;
                height: 206px;
                left: 50%;
                margin-left: -99px;
            }
        }
    }

    .popnonum {
        background: #FFFCF5;
        width: 610px;
        height: 366px;
        border-radius: 40px;
        padding-top: 60px;
        text-align: center;

        .line1 {
            font-size: 36px;
            color: #1A1A1A;
            font-weight: bold;
            line-height: 1;
        }

        .line2 {
            margin-top: 43px;
            line-height: 1;
            font-size: 24px;
            color: #1A1A1A;
            line-height: 42px;
        }

        .num-subline {
            position: absolute;
            left: 36px;
            right: 36px;
            bottom: 44px;
            display: flex;
            justify-content: space-between;

            .numbase {
                width: 260px;
                background: #FF7C7A;
                border-radius: 41px;
                color: #fff;
                text-align: center;
                font-size: 32px;
                color: #FFFFFF;
                background: linear-gradient(90deg, #FF4E00 0%, #FF376F 100%);
                height: 82px;
                line-height: 82px;
            }

            .num-subleft {
                background: #FF7C7A;
            }
        }
    }

    .poprule {
        border-radius: 24px 24px 0px 0px;

        .pop-title {
            height: 140px;
        }

        .rule-content {
            padding: 0 52px 60px;
            height: 700px;
            overflow: auto;

            div {
                font-size: 26px;
                color: #242831;
                line-height: 40px;
                margin-bottom: 30px;
            }
        }

        .pop-close {
            top: 50px;
        }
    }

    .canvascss {
        width: 300PX;
        height: 539PX;
        margin: 0 auto;
        box-sizing: border-box;

        .canvascss_bg {
            width: 100%;
            height: 100%;
        }

        .canvascss_code {
            position: absolute;
            left: 60PX;
            bottom: 7PX;
            width: 66PX;
            height: 71PX;
            background: #fff;
            z-index: 99;
            border-radius: 5PX;
            overflow: hidden;
            border: 1PX solid #E1E1E1;

            img {
                width: 100%;
                height: 65PX;
                position: relative;
                top: -2PX;
            }

            .canvascss_code_text {
                position: absolute;
                bottom: 4PX;
                left: 0;
                right: 0;
                text-align: center;
                line-height: 1;
                font-size: 8PX;
                color: #000000;
            }
        }

        .canvascss_info {
            position: absolute;
            left: 133PX;
            bottom: 26PX;
            padding: 3PX;
            width: 108PX;
            height: 51PX;
            overflow: hidden;
            align-items: center;
            display: flex;

            .canvascss_head {
                width: 45PX;
                height: 45PX;
                border-radius: 7PX;
                margin-right: 6PX;
            }

            .canvascss_full {
                font-weight: bold;
                font-size: 11PX;
                color: #000000;
                flex: 1;

                .canvascss_name {
                    line-height: 1.3;
                }
            }
        }
    }

}
</style>